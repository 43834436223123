<template>
  <div
    id="wrapper"
    class="type-form-wrapper"
    data-tf-widget="LCAnu5"
    data-tf-inline-on-mobile
    data-tf-medium="snippet"
  ></div>
</template>

<script>
export default {
  setup() {
    const embedJs = document.createElement("script");
    embedJs.setAttribute("src", "//embed.typeform.com/next/embed.js");
    document.head.appendChild(embedJs);
  },
};
</script>
<style>
.inner-wrapper {
  @apply h-full;
}
.type-form-wrapper {
  @apply h-full;
}
</style>
