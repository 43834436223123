<template>
  <div
    class="flex w-full bg-white h-screen overflow-y-auto items-start make-items-center"
  >
    <!-- From Column -->
    <div
      class="w-full sm:w-full lg:w-1/2 items-center flex justify-center p-8 md:p-16 h-full select-none"
    >
      <div class="max-w-md mx-auto w-full space-y-12">
        <div class="flex items-center justify-between">
          <Icon class="h-9 w-auto" name="medusa-dist-logo" />
        </div>

        <div>
          <p class="text-brand-primary text-lg font-semibold mb-1">
            Forgot your password?
          </p>
          <span class="text-brand-body font-light"
            >We’ll e-mail you instructions on how to reset it.</span
          >
        </div>

        <form class="w-full space-y-6" @submit.prevent="forgetPassword">
          <div>
            <Input
              v-model:modelValue="userEmail.email"
              :inputClass="
                userEmail.error.email.message || userEmail.error.message
                  ? 'error'
                  : ''
              "
              label="Email Address"
              placeholder="What's your email address?"
            />
            <span
              class="text-sm font-light text-red-400 py-2"
              v-if="userEmail.error.email.message"
              >{{ userEmail.error.email.message }}</span
            >
          </div>
          <div>
            <Button
              icon="right-arrow"
              title="Send link to email"
              type="submit"
              :loader="userEmail.loading"
            />
            <span
              class="text-sm font-normal text-red-400 py-3 inline-flex items-center"
              v-if="userEmail.error.message.length > 0"
              ><ExclamationCircleIcon class="w-4 h-4 mr-1" />{{
                userEmail.error.message
              }}</span
            >
            <span
              class="text-sm font-normal py-3 inline-flex items-center"
              v-if="userEmail.msg.message"
              >{{ userEmail.msg.message }}</span
            >
          </div>
          <div>
            <p class="block text-center text-brand-body text-opacity-70">
              Back to
              <router-link
                to="/login"
                class="text-brand-secondary underline font-semibold"
                >Login</router-link
              >
            </p>
          </div>
          <div>
            <p class="block text-center text-brand-body text-opacity-70">
              Go back to
              <router-link
                to="/"
                class="text-brand-secondary underline font-semibold"
                >Home Page</router-link
              >
            </p>
          </div>
        </form>
      </div>
    </div>

    <!-- Info Column -->

    <InfoColumn />
  </div>
</template>

<script>
import Auth from "@/modules/Auth";
import Input from "@/components/Input.vue";
import InfoColumn from "../common/InfoColumn";
import { ExclamationCircleIcon } from "@heroicons/vue/outline";
export default {
  components: { Input, ExclamationCircleIcon, InfoColumn },
  name: "Login",
  setup() {
    const { user, loggedIn, isLoggedIn, userEmail, forgetPassword } = Auth();

    return {
      user,
      loggedIn,
      isLoggedIn,
      userEmail,
      forgetPassword,
    };
  },
};
</script>
